import React from "react";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";

import { Link } from "@components/global";
import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TWENTY_INCH_SCREEN,
} from "@util/constants";
import { Container } from "@util/standard";
import { urlForImageRef } from "@lib/sanityClient";
import { FontWeights } from "@util/types";

interface Props {
  blocks: any;
  fontSize?: number;
  whiteText?: boolean;
  boldHeaders?: boolean;
}

const Img = styled(Container)<{ url?: string }>`
  margin: 20px 0px 35px 0px;
  height: 700px;
  width: 100%;
  ${({ url }) => url && `background-image: url(${url})`};
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    height: 600px;
  }

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 500px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 400px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
    margin: 20px 0px;
  }
`;

const serializer = {
  types: {
    link: (props: any) => {
      return (
        <Link
          {...props.node}
          opacity={0.5}
          className={props.node.linkDisplay ? props.node.linkDisplay : "custom-link"}
        />
      );
    },
    image: (props: any) => {
      return <Img url={urlForImageRef(props.node.asset).url() as string} />;
    },
  },
};

const StyledContent = styled.div<{ whiteText?: boolean; fontSize?: number; boldHeaders?: boolean }>`
  color: ${props => (props.whiteText ? colors.white : colors.black)};

  h1 {
    font-size: ${fontSizes.h1.default}px;
    font-family: ${fontFamilies.feijoa};
    margin: 0px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h1.mobile}px;
      line-height: ${fontSizes.h1.mobile * 1.2}px;
    }
  }

  h2 {
    font-size: ${fontSizes.h2.default}px;
    font-family: ${fontFamilies.feijoa};

    margin: 0px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h2.mobile}px;
      line-height: ${fontSizes.h2.mobile * 1.2}px;
    }
  }

  h3 {
    font-size: ${fontSizes.h3.default}px;
    font-family: ${fontFamilies.feijoa};

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h3.mobile}px;
      line-height: ${fontSizes.h3.mobile * 1.2}px;
    }
  }

  h6 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-family: ${fontFamilies.proximaNova}, monospace;
    font-size: ${props => props.fontSize ?? fontSizes.p.default}px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${props => props.fontSize ?? fontSizes.p.default}px;
      line-height: ${fontSizes.p.mobile * 1.2}px;
    }
  }

  a {
    text-decoration-line: underline;
    color: ${colors.black};
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${props => (props.whiteText ? colors.white : colors.darkerGreen)};
    ${({ boldHeaders }) => boldHeaders && `font-weight: ${fontWeights.bold}; !important`};
  }
`;

const BlocksContent = ({ blocks, fontSize, whiteText, boldHeaders }: Props) => {
  return (
    <StyledContent fontSize={fontSize} whiteText={whiteText} boldHeaders={boldHeaders}>
      <PortableText
        blocks={blocks}
        serializers={serializer}
        imageOptions={{ auto: "format", fit: "fill" }}
        projectId={process.env.SANITY_PROJECT_ID}
        dataset={process.env.GATSBY_NODE_ENV ?? "development"}
      />
    </StyledContent>
  );
};

export default BlocksContent;
