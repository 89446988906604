import styled from "styled-components";
import { CSSProperties } from "react";

import { colors, colorsRGB, fontFamilies, fontSizes, fontWeights, gradients, PRIMARY_COLOR } from "./constants";
import { FontWeights, Color, Dimensions, FontFamily } from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";
import { Link } from "gatsby";

export const Overlay = styled.div<{ zIndex?: number; }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${props => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  tabletFontSize?: number;
  noMargin?: boolean;
  marginTop?: number;
  marginBottom?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeights;
  opacity?: number;
  isMobileStyling?: boolean;
  display?: CSSProperties["display"];
  whiteSpace?: CSSProperties["whiteSpace"];
  cursor?: CSSProperties["cursor"];
  padding?: string;
  textAlign?: string;
  letterSpacing?: string;
  margin?: string;
  textDecoration?: CSSProperties["textDecoration"];
  userSelect?: string;
  zIndex?: number;
  width?: string;
  mobileWidth?: string;
  isBold?: boolean;
}>`
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  background-color: transparent;
  font-weight: ${props => (props.fontWeight ? fontWeights[props.fontWeight] : fontWeights.normal)};
  font-family: ${fontFamilies.proximaNova};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width};`};
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`};
  ${({ textDecoration }) => textDecoration && `text-decoration: ${textDecoration};`};
  ${({ isBold }) => isBold && `font-weight: ${fontWeights["bold"]};`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: ${fontSizes.p.mobile}px;
    line-height: ${fontSizes.p.mobile * 1.2}px;
    ${({ tabletFontSize }) => tabletFontSize && `font-size: ${tabletFontSize}px;`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ tabletFontSize }) => tabletFontSize && `font-size: ${tabletFontSize}px;`};
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
  }

  ${({ isMobileStyling }) =>
    isMobileStyling &&
    `@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    text-align: center;
    margin: auto;
  }`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  underlineOnHover?: boolean;
  opacity?: number;
  width?: string;
  margin?: string;
  fontSize?: number;
  fontFamily?: string;
  cursor?: CSSProperties["cursor"];
  fontWeight?: FontWeights;
  textDecoration?: CSSProperties["textDecoration"];
  mobileMargin?: string;
}>`
  ${({ color }) => (color ? `color: ${colors[color]};` : `color: ${colors.black}`)};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px;` : `font-size: ${fontSizes.p.default}px;`)};
  ${({ fontFamily }) => (fontFamily ? `font-family: ${fontFamily};` : `font-family: ${fontFamilies.proximaNova};`)};
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : `font-weight: ${fontWeights.semibold};`)};
  ${({ textDecoration }) => textDecoration && `text-decoration: ${textDecoration};`};
  cursor: pointer;

  ${({ cursor }) => cursor && `cursor: ${cursor};`};
  ${({ width }) => width && `width: ${width};`};
  ${({ underlined }) => (underlined ? "text-decoration: underline;" : "text-decoration: none;")}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ underlineOnHover }) => underlineOnHover && `&:hover { text-decoration: underline;};`};
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
    color: ${colors[hoverColor]};
  }`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`};
  }
`;
export const H1 = styled.h1<{
  noMargin?: boolean;
  fontWeight?: string;
  fontSize?: number;
  color?: Color;
  width?: string;
}>`
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  font-size: ${props => props.fontSize ?? fontSizes.h1.default}px;
  font-family: ${fontFamilies.feijoa};
  ${({ width }) => width && `width: ${width};`};

  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h1.mobile}px;
    line-height: ${fontSizes.h1.mobile * 1.2}px;
  }
`;
export const H2 = styled.h2<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeights;
  fontSize?: number;
  mobileFontSize?: number;
  isBold?: boolean;
  textAlign?: CSSProperties["textAlign"];
}>`
  font-size: ${fontSizes.h2.default}px;
  font-weight: ${fontWeights.normal};
  font-family: ${fontFamilies.feijoa};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`};
  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ isBold }) => isBold && `font-weight: ${fontWeights.bold};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;

    ${({ mobileFontSize }) => mobileFontSize && `font-size: ${mobileFontSize}px;`};
    line-height: ${fontSizes.h2.mobile * 1.2}px;
    ${({ isBold }) => isBold && `font-size: ${fontSizes.h2.mobile + 3};`}
  }
`;

export const H3 = styled.h3<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeights;
  fontFamily?: FontFamily;
  fontSize?: number;
  mobileFontSize?: number;
  mobileLineHeight?: string;
  width?: string;
  tabletWidth?: string;
  isBold?: boolean;
  opacity?: number;
  cursor?: CSSProperties["cursor"];
}>`
  font-size: ${fontSizes.h3.default}px;
  font-weight: ${props => props.fontWeight ?? fontWeights.normal};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamilies[fontFamily]};`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px`};
  ${({ width }) => width && `width: ${width};`};
  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`};
  ${({ isBold }) => isBold && `font-weight: ${fontWeights.bold};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h3.mobile}px;
    line-height: ${fontSizes.h3.mobile * 1.2}px;
    ${({ mobileFontSize }) => mobileFontSize && `font-size: ${mobileFontSize}px;`};
    ${({ mobileLineHeight }) => mobileLineHeight && `line-height: ${mobileLineHeight};`};
  }
`;

export const H4 = styled.h4<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeights;
  fontFamily?: FontFamily;
  isBold?: boolean;
  width?: string;
}>`
  font-size: ${fontSizes.h4.default}px;
  font-family: ${fontFamilies.feijoa};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamilies[fontFamily]};`};
  white-space: initial;
  ${({ width }) => width && `width: ${width};`};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ isBold }) => isBold && `font-weight: ${fontWeights.bold}`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h4.mobile}px;
    line-height: ${fontSizes.h4.mobile * 1.2}px;
  }
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  height: ${props => (props.height ? props.height : `auto`)};
  width: ${props => (props.width ? props.width : `20px`)};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`)};
  border-bottom: ${props => props.thickness ?? 1}px solid
    ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${props => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: CSSProperties["flexDirection"];
  tabletFlexDirection?: CSSProperties["flexDirection"];
  flex?: number | string;
  flexGrow?: number;
  flexWrap?: CSSProperties["flexWrap"];
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  mobileAlignSelf?: CSSProperties["alignSelf"];
  borderRadius?: number;
  overflow?: CSSProperties["overflow"];
  overflowY?: CSSProperties["overflow"];
  hideScrollBar?: boolean;
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  tabletWidth?: string;
  tabletHeight?: string;
  mobileWidth?: string;
  mobileJustifyContent?: CSSProperties["justifyContent"];
  mobileDimensions?: Dimensions;
  tabletDimensions?: Dimensions;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  mobileHeight?: string;
  maxHeight?: string;
  minHeight?: string;
  opacity?: number;
  margin?: string;
  tabletMargin?: string;
  mobileMargin?: string;
  padding?: string;
  tabletPadding?: string;
  cursor?: CSSProperties["cursor"];
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
  display?: string;
  position?: string;
  transitionAll?: number;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  boxShadow?: string;
  translateX?: string;
  borderBottom?: string;
  borderTop?: string;
  border?: string;
}>`
  display: ${props => props.display ?? "flex"};
  ${({ position }) => position && `position: ${position}`};
  flex-direction: ${props => props.flexDirection ?? "initial"};
  justify-content: ${props => props.justifyContent ?? "initial"};
  align-items: ${props => props.alignItems ?? "initial"};
  align-self: ${props => props.alignSelf ?? "initial"};
  overflow: ${props => props.overflow ?? "initial"};
  ${({ hideScrollBar }) =>
    hideScrollBar &&
    ` ::-webkit-scrollbar {
    display: none;
}`}

  ${({ overflowY }) => overflowY && `overflow-y: ${overflowY};`};
  border-radius: ${props => props.borderRadius ?? 0}px;
  background-size: cover;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`};
  ${({ paddingVertical }) => paddingVertical && `padding: 0px ${paddingVertical}px 0px ${paddingVertical}px;`}
  ${({ paddingHorizontal }) => paddingHorizontal && `padding: ${paddingHorizontal}px 0px ${paddingHorizontal}px 0px;`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) => backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ minWidth }) => minWidth && `min-width: 50%;`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
  ${({ height }) => height && `height: ${height};`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom};`};
  ${({ borderTop }) => borderTop && `border-top: ${borderTop};`};
  ${({ border }) => border && `border: ${border};`};

  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}
  ${({ top }) => top && `top:${top};`};
  ${({ bottom }) => bottom && `bottom:${bottom};`};
  ${({ left }) => left && `left:${left};`};
  ${({ right }) => right && `right:${right};`};
  ${({ translateX }) => translateX && `transform: translateX(${translateX});`};
  ${({ transitionAll }) => transitionAll && `transition: all ${transitionAll}s`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
    isMobileColumn &&
    `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}
    ${({ tabletHeight }) => tabletHeight && `height: ${tabletHeight};`};
    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding};`};
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
    ${({ tabletDimensions }) => tabletDimensions && tabletDimensions.height && `height: ${tabletDimensions.height};`}
    ${({ tabletDimensions }) => tabletDimensions && tabletDimensions.width && `width: ${tabletDimensions.width};`}
    ${({ tabletFlexDirection }) => tabletFlexDirection && `flex-direction: ${tabletFlexDirection}`};
    ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
    ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) => mobileDimensions && mobileDimensions.height && `height: ${mobileDimensions.height};`}
    ${({ mobileDimensions }) => mobileDimensions && mobileDimensions.width && `width: ${mobileDimensions.width};`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`};

    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) => mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
    ${({ mobileHeight }) => mobileHeight && `height: ${mobileHeight};`};
    ${({ mobileJustifyContent }) => mobileJustifyContent && `justify-content: ${mobileJustifyContent};`}
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  columnGap?: string;
  rowGap?: number;
  mobileColumnGap?: string;
  mobileRowGap?: number;
  rtl?: boolean;
  overflowX?: string;
  tabletRowGap?: number;
}>`
  display: grid;
  grid-template-columns: repeat(${props => props.repeat ?? 4}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap};`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}
  ${({ overflowX }) => overflowX && `overflow-x: ${overflowX};`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(${props => props.tabletRepeat ?? 2}, 1fr);
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`}
    ${({ tabletRowGap }) => tabletRowGap && `grid-row-gap: ${tabletRowGap}px;`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(${props => props.mobileRepeat ?? 1}, 1fr);
    ${({ mobileColumnGap }) => mobileColumnGap && `grid-column-gap: ${mobileColumnGap};`}
    ${({ mobileRowGap }) => mobileRowGap && `grid-row-gap: ${mobileRowGap}px;`}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
  }
`;

export const TextButton = styled(P) <{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
  bold?: boolean;
}>`
  ${({ margin }) => margin && `margin: ${margin};`}
  line-height: ${props => props.lineHeight ?? 18}px;
  font-size: ${fontSizes.p}px;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  cursor: pointer;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${props => (props.bold ? fontWeights.bold : fontWeights.normal)};

  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${props => (props.hoverColor ? colors[props.hoverColor] : colors.mossGreen)};
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number; }>`
  padding: ${props => props.space ?? 10}px;
`;

export const Gradient = styled.div<{ gradient: string; hoverEffectOn?: boolean; }>`
  width: 100%;
  height: 100%;
  position: absolute;
  ${({ gradient }) => gradient && `background: ${gradient};`};
  opacity: 0.6;
  ${({ hoverEffectOn }) => hoverEffectOn && `opacity: 1;`};
  transition: all 0.2s;
`;

export const LinkContainer = styled(Link) <{
  width?: string;
  margin?: string;
  height?: string;
  maxHeight?: string;
  tabletMargin?: string;
  tabletWidth?: string;
  mobileWidth?: string;
}>`
  ${({ width }) => (width ? `width: ${width};` : `width: 100%;`)};
  ${({ margin }) => (margin ? `margin: ${margin};` : `margin: 0;`)};
  ${({ height }) => (height ? `height: ${height};` : `height: 100%;`)};
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : `max-height: 100%;`)};
  text-decoration: none;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
  }
`;
